.PublicContainer {
  color: white !important;
  font-weight: 'regular' !important;
  text-align: center;
  background-image: url('./LegitFishBackgroundImage.jpg');
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  max-width: 100% !important;
  min-height: 100vh;
}
