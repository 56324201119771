@import url('https://fonts.googleapis.com/css?family=Rubik:300,400,500');

html {
  font-size: 20px;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  /* Styles */
  html {
    font-size: 16px;
  }
}

/*@media screen and (min-width: 25em){
  html { font-size: calc( 16px + (20 - 16) * (100vw - 400px) / (800 - 400) ); }
}*/

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
