.carousel .slide {
  background: #fff !important;
}

.carousel .control-dots {
  z-index: 200;
  padding-bottom: 25px;
  padding-left: 0px;
}

.carousel .slide iframe {
  width: 100% !important;
  min-height: 40vh !important;
  margin: 0px 0px 0px 0px !important;
}
