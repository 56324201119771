@import url(https://fonts.googleapis.com/css?family=Rubik:300,400,500);
html {
  font-size: 20px;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  /* Styles */
  html {
    font-size: 16px;
  }
}

/*@media screen and (min-width: 25em){
  html { font-size: calc( 16px + (20 - 16) * (100vw - 400px) / (800 - 400) ); }
}*/

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.AppContainer {
  text-align: left;
  white-space: pre-line;
  font-weight: 'regular' !important;
}

.PublicContainer {
  color: white !important;
  font-weight: 'regular' !important;
  text-align: center;
  background-image: url(/static/media/LegitFishBackgroundImage.cfd3f966.jpg);
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  max-width: 100% !important;
  min-height: 100vh;
}

.ErrorContainer {
  padding-top: 60px;
}

.carousel .slide {
  background: #fff !important;
}

.carousel .control-dots {
  z-index: 200;
  padding-bottom: 25px;
  padding-left: 0px;
}

.carousel .slide iframe {
  width: 100% !important;
  min-height: 40vh !important;
  margin: 0px 0px 0px 0px !important;
}


